import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { BiMenuAltRight, BiX } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  return (
    <div>
      <nav className="bgwhite shadow-md w-full bg-transparent">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center"> {/* Center-align content */}
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                <button
                  onClick={toggleSidebar}
                  className="inline-flex items-center justify-center p-2 rounded-md text-[white] hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:[#38be57]"
                >
                  <span className="sr-only">Toggle Sidebar</span>
                  {openSidebar ? <BiX className="h-6 w-6 text-[#38be57]" /> : <BiMenuAltRight className="h-6 w-6 text-[#38be57]" />}
                </button>
              </div>
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block h8 xl:w-[15%] w-[15%]"
                  src="drax.png"
                  alt=""
                />
              </div>
            </div>
            <div className="hidden md:block pt-5">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavLink
                  to="/"
                  className="text-[#c4c4c4] hover:text-gray-700"
                  activeClassName="bg-gray-100 text-[white]"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  className="text-[#c4c4c4] hover:text-gray-700"
                  activeClassName="bg-gray-100 text-gray-700"
                >
                  About
                </NavLink>
                <div className="flex">
              <button
                onClick={toggleServices}
                className="text-[#c4c4c4] hover:text-gray-700"
              >
                Services
              </button> <i class="ri-arrow-down-s-line text-[black] cursor-pointer" onClick={toggleServices}></i>
            </div>
            {isServicesOpen && (
              <div className=" fixed z-50  mt-5 bg-white border border-gray-200 rounded-md shadow-lg w-[13rem]">
                <div className="flex justify-end">
                <i class="ri-close-line text-black pb- cursor-pointer text-[1.3rem]" onClick={toggleServices}></i>
                </div>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Blackmail/Extortion
                  </Link>
                  <Link to="/Crypto" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Crypto Recovery
                  </Link>
                  <Link to="/AccountUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Account Recovery/Unlock
                  </Link>
                  <Link to="/Romance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Romance Scam Recovery
                  </Link>
                  <Link to="/Social" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Social Media Hack/Recovery
                  </Link>
                  <Link to="/PhoneUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Phone/Device Unlock
                  </Link>
                </div>
              </div>
            )}
                <NavLink
                  to="/contact"
                  className="text-[#c4c4c4] hover:text-gray-700"
                  activeClassName="bg-gray-100 text-gray-700"
                >
                  Contact
                </NavLink>
                <a
                  href="#FAQ"
                  className="text-[#c4c4c4] hover:text-gray-700"
                  activeClassName="bg-gray-100 text-gray-700"
                >
                  FAQ
                </a>
                <a
                  href="#Review"
                  className="text-[#c4c4c4] hover:text-gray-700"
                  activeClassName="bg-gray-100 text-gray-700"
                >
                  Reviews
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`md:hidden z-40 fixed top-0 left-0 h-screen w-56 transform 
        ${openSidebar ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-[#000]`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <button
            onClick={toggleSidebar}
            className="flex justify-end p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:[#a5c638]"
          >
            <span className="sr-only">Close Sidebar</span>
            <BiX className="h-6 w-6 text-[#38be57]" />
          </button>
          <NavLink
            to="/"
            className="text-[#c4c4c4] hover:text-gray-700 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className="text-[#c4c4c4] hover:text-gray-700 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            About
          </NavLink>
          <div className="flex">
              <button
                onClick={toggleServices}
                className="text-[#c4c4c4] hover:text-gray-700 pl-3"
              >
                Services
              </button> <i class="ri-arrow-down-s-line text-[black] cursor-pointer" onClick={toggleServices}></i>
            </div>
            {isServicesOpen && (
              <div className=" fixed z-50  mt-5 bg-white border border-gray-200 rounded-md shadow-lg w-[13rem]">
                <div className="flex justify-end">
                <i class="ri-close-line text-black pb- cursor-pointer text-[1.3rem]" onClick={toggleServices}></i>
                </div>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu ">
                  <Link to="/Blackmail" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Blackmail/Extortion
                  </Link>
                  <Link to="/Crypto" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Crypto Recovery
                  </Link>
                  <Link to="/AccountUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Account Recovery/Unlock
                  </Link>
                  <Link to="/Romance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Romance Scam Recovery
                  </Link>
                  <Link to="/Social" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Social Media Hack/Recovery
                  </Link>
                  <Link to="/PhoneUnlock" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    Phone/Device Unlock
                  </Link>
                </div>
              </div>
            )}
          <NavLink
            to="/contact"
            className="text-[#c4c4c4] hover:text-gray-700 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            Contact
          </NavLink>
          
          <a
            href="#FAQ"
            className="text-[#c4c4c4] hover:text-gray-700 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            FAQ
          </a>
          <a
            href="#Review"
            className="text-[#c4c4c4] hover:text-gray-700block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            Reviews
          </a>
          <NavLink
            to="https://draxallcorp.com/signup/"
            className="text-[#c4c4c4] hover:text-gray-700 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            Sign-Up
          </NavLink>
          <NavLink
            to="https://draxallcorp.com/signup/login"
            className="text-[#c4c4c4] hover:text-gray-700 block px-3 py-2 rounded-md text-base font-medium"
            activeClassName="bg-gray-100 text-gray-700"
          >
            Login
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
