import React, { useState } from 'react';
import {   FaEnvelope } from 'react-icons/fa';
import {BiLogoInstagramAlt} from "react-icons/bi";
// import { BiLogoTelegram } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

const BusinessFooter = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage('Please enter your email.');
    } else {
      setIsSubscribed(true);
      setErrorMessage('');
      setEmail('');
    }
  };

  return (
    <footer className="bg-black text-white py-4 px-2">
      <div className="max-w-6xl mx-auto flex flex-wrap">
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Contact</h3>
         <a href="/"><p>Email: draxallcorp@gmail.com</p></a> 
          {/* <p>Phone: (123) 456-7890</p> */}
          <p>Address: Memphis</p>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
          <div className="flex space-x-3 mb-5">
            <a href="https://www.instagram.com/draxall/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==" className="text-white hover:text-gray-500">
              <BiLogoInstagramAlt size={24} />
            </a>
            <a href="mailto:draxallcorp@gmail.com" className="text-white hover:text-gray-500">
              <FaEnvelope size={24} />
            </a>

            <a href="https://wa.me/+18019093739" className="text-white hover:text-gray-500">
              <FaWhatsapp size={24} />
            </a>
           
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Subscribe</h3>
          {isSubscribed ? (
            <p className="text-green-500">Thank you for subscribing!</p>
          ) : (
            <form onSubmit={handleSubmit} className="mt-4 flex items-center">
              <input
                type="email"
                className="px-4 py-2 w-full rounded-l-lg focus:outline-none text-black"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className="px-4 py-2 bg-[#38be57] text-[white] rounded-r-lg">
                Subscribe
              </button>
            </form>
          )}
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      </div>
      <div className="max-w-6xl mx-auto mt-8 text-center">
        <p className="text-sm">&copy; 2014 draxallcorp.com All rights reserved.</p>
        <p className="text-sm">Terms of Service | Privacy Policy</p>
      </div>
    </footer>
  );
};

export default BusinessFooter; 
