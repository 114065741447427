import React, {useState} from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Avatar from '@mui/material/Avatar';
import { useRef, useEffect} from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Review from "./hooks/Review";
import { Link } from 'react-router-dom';
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";

const Home = () => {
    const Accordion = ({ title, content }) => {
        const [isOpen, setIsOpen] = useState(false);
      
        return (
          <div className="border rounded shadow mb-4">
            <div
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-200"
              onClick={() => setIsOpen(!isOpen)}
            >
              <h2 className="text-lg font-semibold">{title}</h2>
              <svg
                className={`w-6 h-6 transition-transform duration-300 transform text-[#38be57] ${
                  isOpen ? 'rotate-45' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isOpen ? 'M6 18L18 6' : 'M6 18L18 6M6 6l12 12'}
                />
              </svg>
            </div>
            {isOpen && (
              <div className="p-4 bg-white">
                <p>{content}</p> <br />
              </div>
            )}
          </div>
        );
      };
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
    
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
    
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
    
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
        bgcolor: '#38be57',
        
      };
    
      const fabWhiteStyle = {
        color: 'common.white',
        bgcolor: '#38be57',
        '&:hover': {
          color: '#38be57', // Change the color on hover
          bgcolor: 'transparent',
        },
      };
    
      const [value, setValue] = React.useState(0);
      const isMobile = useMediaQuery('(max-width: 600px)');
    
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
      const transitionDuration = 500;
    
      const fabs = [
        {
          color: 'primary',
          sx: fabStyle,
          icon: <StarIcon />, // Icon for Excellence
          label: 'Excellence',
        },
        {
          color: 'secondary',
          sx: fabStyle,
          icon: <SecurityIcon />, // Icon for Integrity
          label: 'Integrity',
        },
        {
          color: 'inherit',
          sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
          icon: <GroupIcon />, // Icon for Teamwork
          label: 'Teamwork',
        },
      ];
  return (
    <>
    <Progress/>
    <section class="herox overflow-hidden">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Trusted Partner for Recovering Lost Funds from Scams!</p>
                                </motion.div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Combining Expertise and  Advanced Technology for Fast Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <div>
                                   
                                 </div>
                                    <p className='text-white'>
                                    We specialize in professional fund recovery services for individuals and businesses, guaranteeing you 
                                    a quick and effortless process for reclaiming your assets.
                                    </p>
                                </motion.div>
                                 <div className='pt-5 flex gap-3'>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#38be57]'>
                                            <a href="https://www.instagram.com/draxall/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                                            <i class="ri-instagram-line"></i>
                                            </a>
                                        </Avatar>
                                    </motion.div>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.7, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#38be57]'>
                                            <a href="mailto:draxallcorp@gmail.com">
                                                <i class="ri-mail-open-line"></i>
                                            </a>
                                        </Avatar>
                                    </motion.div>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#38be57]'>
                                            <a href="https://wa.me/+18019093739">
                                            <i class="ri-whatsapp-line"></i>
                                            </a>
                                            </Avatar>
                                    </motion.div>
                                 </div>
                                <div className='flex gap-5'>
                                <div className='bg-[reen] h-fit flex gap-1 mr-1 mt-5'>
                                   <a href="/Contact"><button className='get__one'>Report Scam <i class="ri-arrow-right-line"></i></button></a>
                                </div>
                                <div className='bg-[reen] h-fit flex gap-1 mr-1 mt-5'>
                                   <a href="https://draxallcorp.com/signup/"><button className='get__one2'>Sign-Up Now<i class="ri-arrow-right-line"></i></button></a>
                                </div>
                                </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
    </section>


        <div className='bg-black h-fit'>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <motion.div className='bg-reen-600 h-fit Shadow__x'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}> 
                        <div className='pl-5 pt-5 pb-2'>
                            <img src="tax-revenue.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-2 pb-5'>
                            <p className='text-white font-bold'>Refund Policy</p>
                        </div>

                        <div className='pl-5 pt-2 pb-5 pr-2'>
                            <p className='text-white'>
                            With a wealth of expertise at our disposal, we maintain a high success rate, minimizing refund requests. 
                            If you're unsatisfied, rest assured that we offer a swift and hassle-free refund process.
                            </p>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit Shadow__x'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}> 
                        <div className='pl-5 pt-5 pb-2'>
                            <img src="accurate.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-2 pb-5'>
                            <p className='text-white font-bold'>Accuracy</p>
                        </div>

                        <div className='pl-5 pt-2 pb-5 pr-2'>
                            <p className='text-white'>
                            Discover the pure excellence within our highly adaptable and versatile team, rich in proficiency across various fields. Witness the seamless harmony as we come together to expertly organize and achieve flawless results.
                            </p>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit Shadow__x'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}> 
                        <div className='pl-5 pt-5 pb-2'>
                            <img src="resilience.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-2 pb-5'>
                            <p className='text-white font-bold'>Resilience</p>
                        </div>

                        <div className='pl-5 pt-2 pb-5 pr-2'>
                            <p className='text-white'>
                            Within our recovery agency, we embody the essential quality of resilience. Our commitment to assisting clients in challenging situations remains steadfast. With a wide range of comprehensive skills.
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className='mx-auto overflow-hidden  md:max-w-full mt-10'>
                <div className='Numbers'>
                    <div className='mx-auto overflow-hidden  md:max-w-7xl relative'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
                                <div className='bg-green-00 h-fit'>
                                    <div>
                                        <p className='font-bold text-[1.5rem] text-white '> <span className='top__b'>About Us</span></p>
                                    </div>

                                    <div className='pt-5'>
                                        <p className='text-[25px] font-bold text-white'>Pioneering Advanced Technological <br />
                                         <span className='text-[#38be57]'>Solutions Delivery</span> </p>
                                    </div>

                                    <div><br />

                                        <p className='text-[#dfdcdc]'>
                                        Technology facilitates the advancement and exchange of information. The fundamental goal of information technology (IT) is to optimize operations through inventive multitasking. At Draxall Corp Technology, our adept team leverages these resources to effectively and openly tackle cybersecurity hurdles.
                                        </p>
                                    </div>

                                    <div className='mt-5 Abt_left'>
                                      <a href="/About"><button className='About__btn'>See More <i class="ri-arrow-right-line "></i></button></a>  
                                    </div>

                                </div>
                                <div className='bg-geen-600 h-fit xl:pt-10 pt-0'>
                                    <div className='flex gap-3'>
                                        <motion.div className='bg-white h-fit w-full rounded-md'style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true, amount: 0.5 }}
                                        transition={{ delay: 0.3, duration: 0.5 }}              
                                        variants={{
                                        hidden: { opacity: 0, y: 50 },
                                        visible: { opacity: 1, y: 0 },
                                        }}>
                                                <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#38be57]'>$<AnimatedNumbers value={27}/>m+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Money Restored</p>
                                                </div>
                                            </motion.div>
                                            <motion.div className='bg-white h-fit w-full rounded-md' style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true, amount: 0.5 }}
                                            transition={{ delay: 0.4, duration: 0.5 }}              
                                            variants={{
                                            hidden: { opacity: 0, y: 50 },
                                            visible: { opacity: 1, y: 0 },
                                            }}>
                                            <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#38be57]'><AnimatedNumbers value={12000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Relied Upon By</p>
                                                </div>
                                            </motion.div>
                                    </div>
                                    
                                        <div className='flex gap-3 mt-3'>
                                        <motion.div className='bg-white h-fit w-full rounded-md'style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true, amount: 0.5 }}
                                        transition={{ delay: 0.5, duration: 0.5 }}              
                                        variants={{
                                        hidden: { opacity: 0, y: 50 },
                                        visible: { opacity: 1, y: 0 },
                                        }}>
                                                <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#38be57]'><AnimatedNumbers value={10000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Satisfied Clients</p>
                                                </div>
                                            </motion.div>
                                            <motion.div className='bg-white h-fit w-full rounded-md' style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true, amount: 0.5 }}
                                            transition={{ delay: 0.6, duration: 0.5 }}              
                                            variants={{
                                            hidden: { opacity: 0, y: 50 },
                                            visible: { opacity: 1, y: 0 },
                                            }}>
                                            <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#38be57]'><AnimatedNumbers value={9000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Secured Clients</p>
                                                </div>
                                            </motion.div>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>  
            </div>

            <div className='mx-auto overflow-hidden md:max-w-7xl text-[#e7e5e5]'>
                <div className='grid mt-10 xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-3 pb-10 pt-[5%]'>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[black] !w-[60px] !h-[60px] border !border-[#38be57]">
                                <img src="bitcoin.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                    Crypto Currency Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-10 pt-3">
                            <p>
                            Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, 
                            for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, 
                            you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance, 
                            led by draxall corp and their dedicated team of professionals.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Crypto" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[black] !w-[60px] !h-[60px] border !border-[#38be57]">
                                <img src="hearts.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                    Romance Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these 
                            deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through 
                            the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment. we guide 
                            you in overcoming distress and guide you through the recovery of your lost assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5 pt-5">
                            <a href="/Romance" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[black] !w-[60px] !h-[60px] border !border-[#38be57]">
                                <img src="blackmail.png" alt="" className="!w-[124px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Blackmail / Extortion Help
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-10 pt-3">
                            <p>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our dedicated mission is to 
                            provide unwavering support and compassionate assistance to individuals who have unfortunately encountered these 
                            distressing experiences. We steadfastly stand by their side, offering expert guidance to those who have fallen 
                            prey to online threats and malicious intent.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Blackmail" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[black] !w-[65px] !h-[65px] border !border-[#38be57]">
                                <img src="social.png" alt="" className="!w-[100px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Social Media Spy/Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished 
                            connections. Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving 
                            no stone unturned in ensuring your swift account recovery. Trust in our unwavering expertise and commitment to 
                            promptly reinstate your access
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Social" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.7, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[black] !w-[60px] !h-[60px] border !border-[#38be57]">
                                <img src="responsive.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Phone Hack/unlock
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            draxall corp, Comprising a team of esteemed specialists in phone unlocking, rest assured, no detail 
                            escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee thoroughness in each phase,
                            delivering definitive solutions tailored to your requirements. Place your trust in us for an unparalleled, 
                            hassle-free experience & Our mission is to safeguard your digital assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/PhoneUnlock" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.8, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[black] !w-[60px] !h-[60px] border !border-[#38be57]">
                                <img src="activation.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Account Unlock / Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the 
                            convenience of our solutions designed to overcome account lockouts effortlessly.
                            At draxall crop, we specialize in swift account recovery, providing you with the means to 
                            regain control and peace of mind for your digital assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5 mt-5">
                            <a href="/AccountUnlock" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                </div>
            </div>


            <div className='bg-[#060606]'>
        
             <div className="mx-auto overflow-hidden  md:max-w-7xl">
             <div className='xl:ml-2 ml-5'>
            <p className='font-bold text-[25px] pt-10 text-white'>
                OUR  CORE <span className='Policy_span'>VALUES</span> 
            </p>
        </div>
            <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                <div className="bg-green-00 h-fit">
                    <div className="flex justify-center">
                        <img src="https://media.licdn.com/dms/image/D5612AQEoZLz-i2pZbQ/article-cover_image-shrink_720_1280/0/1690211765763?e=2147483647&v=beta&t=VVNXM5nwkv1tMWSKlVv-llo_GD06D81N_c4jM7ZectY" alt=""
                        className="rounded-md"/>
                    </div>
                </div>
                <div className="bg-green-00 h-fit">
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: '#060606',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: "black" }}>
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#38be57" // Change this to "#29d090"
           textColor="#5045E4"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#38be57" } }} 
          >
            <Tab label="Expertise" {...a11yProps(0)} />
            <Tab label="Ethical" {...a11yProps(1)} />
            <Tab label="Resilience" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div className='text-white'>
            <p className='font-bold'>Expertise and Experience:</p>
          </div>
          <div className='pt-3 text-white'>
          Through ongoing enhancement, we labor diligently and persistently to exceed your expectations. Our steadfast dedication ensures that we won't cease our efforts until we've attained excellence and fulfilled the task to your highest satisfaction.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div  className='text-white'>
            <p className='font-bold'>Unwavering Ethical Commitment</p>
          </div>
          <div className='pt-3 text-white'>
          We maintain the utmost standards of trustworthiness and ethical conduct. Our unwavering commitment to integrity is the foundation of our service, guaranteeing your complete confidence in our work. We are devoted to preserving your trust continuously, recognizing that your peace of mind is of paramount importance.

          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className='text-white'>
            <p className='font-bold'>Unified Resilience</p>
          </div>
          <div className='pt-3 text-white'>
          Our dynamic collaboration thrives on collective determination. We join forces as a committed team, confronting challenges directly and achieving results together. With steadfast determination, we seamlessly work towards realizing your goals at every step of the process.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
                    <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Expertise</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
               </div>
                </div>
            </div>

       
             </div>




                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className='xl:ml-2 ml-5'>
                        <p className='font-bold text-[25px] pt-10 text-white'>
                             OUR  TOP <span className='Policy_span'>TESTIMONIES</span> 
                        </p>
                    </div>
                </div>
                <Review/>
            </div>

      </div>


      <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='flex justify-center'>
                         <p className='text-white pt-10'>F.A.Q</p>
                    </div>
                    <div className='flex justify-center'>
                        <p className='text-white font-bold text-[2.5rem] text-center'>Frequently Ask Questions</p>
                    </div>
            </div>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>
                            <div className='bg-green-00 h-fit'>
                                <Accordion
                                    title="100% Refund Ploicy"
                                    content="draxallcorp is our unwavering commitment to delivering superior results and 
                                    meeting the high expectations of our valued clients. While we have full confidence in our ability 
                                    to accomplish the given tasks, we acknowledge that unforeseen circumstances may occasionally arise, 
                                    potentially impacting our ability to honor our commitments. In such cases, if we find ourselves 
                                    unable to fulfill a task, we stand by our commitment to customer satisfaction by offering a refund, 
                                    ensuring trust in our brand
                                    At the heart of our operations lie principles of transparency and fairness in all our dealings. Our 
                                    terms and conditions are thoughtfully designed to provide clarity and protection for both our clients 
                                    and our team. Our dedicated focus remains on delivering exceptional service and value to our clients. 
                                    Continuously, we strive to improve our processes, consistently surpassing expectations.
                                    "
                                />
                                <Accordion
                                   title="How does your recovery firm operate?"
                                    content="Our firm, specializing in scam recovery, is steadfast in its commitment to aiding victims of 
                                    fraudulent schemes throughout the intricate process of reclaiming their lost funds. Employing a 
                                    multi-faceted approach, we merge specialized expertise, cutting-edge technology, and legal resources 
                                    to offer the utmost support to our clients.
                                    Central to our operations is a team comprising seasoned professionals with a niche focus on the complex 
                                    realm of scam recovery. These adept experts harness a blend of investigative methodologies and financial
                                     expertise to meticulously unravel the intricate web of transactions associated with the scam. 
                                     Through exhaustive tracking of the financial trail, we meticulously pinpoint and segregate the assets 
                                     rightfully belonging to you.
                                    "
                                />
                            </div>
                            <div className='bg-green600 h-fit'>
                                <Accordion 
                                    title="Can I Trust This Firm?" 
                                    content="We acknowledge the pivotal importance of trust and authority when it comes to our clients 
                                    feeling secure and confident in sharing sensitive financial information with our team. We are fully 
                                    aware that the decision to entrust us with such critical information necessitates careful 
                                    consideration, a responsibility we take with the utmost seriousness.
                                    We firmly believe that trust is not simply given, but earned through a continuous display of 
                                    consistent and dependable performance. Our commitment lies in surpassing our clients' expectations 
                                    in every facet of our work, striving to foster an environment of reliability and excellence."
                                    
                                    />
                                <Accordion 
                                    title="How Long dose the process take?" 
                                    content="The process of recovering lost Cryptocurrency spans a variable timeline, fluctuating between 
                                    durations as short as one hour and potentially extending to two months or more. This duration may seem 
                                    extensive due to our comprehensive initial search conducted within the first week of onboarding a 
                                    client. After this exhaustive search, we retain the encrypted private backup and employ password 
                                    guesses to perpetuate testing, identifying new recovery vectors, and enhancing our hardware 
                                    capabilities.
                                    Respecting the privacy and security of our clients, we maintain client information unless expressly 
                                    requested for deletion. Our commitment to safeguarding client privacy remains unwavering, adhering 
                                    to strict protocols in data management
                                    " 
                                />
                            </div>
                        </div>
            </div>
       



                    <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>
        </div>
       <div>
        <Footer/>
       </div>
    </>
  )
}

export default Home