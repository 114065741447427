import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://static-cdn.jtvnw.net/jtv_user_pictures/7be68a11-9eb3-4d35-9a36-ef75870956e7-profile_image-300x300.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Christopher Smith</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            A cybercriminal infiltrated my social media account, exploiting it for fraudulent activities. 
        Feeling distraught and urgently needing to regain authority, I discovered DraxallCorp—a team of skilled 
        professionals who promptly and efficiently assisted me in reclaiming my account. Their rapid response and 
        expertise were truly impressive, restoring my confidence in managing my online presence.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-4'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-4'>California, USA  🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNjO1FLSX9h1JNlJU9UwTo8X0j9NRSvz_d9O6B5REju3BwjvCkdmWQP9abpBgL6MVtnCs&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Michael Brown</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
                An online scammer breached my social media account and started soliciting funds from my Facebook friends, resulting in considerable harm to my reputation. Overwhelmed and seeking assistance, I received a suggestion to reach out to DraxallCorp. Initially uncertain about their upfront payment request, I eventually took the risk, and to my delight, they swiftly recovered my account without any delay.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>San Francisco, USA  🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos.spareroom.com/images/flatshare/listings/large/20/24/202463365.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Emily Rodriguez</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
          My Instagram account  of 789k Followers was disabled for months, depriving me of countless cherished memories. Despite my efforts, I couldn't recover it. Then, I discovered DraxallCorp, and they effortlessly restored my account. Not only that, but they also bolstered my account's security. I'm grateful to have found this remarkable team.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[13%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[13%]'>Madrid, Spain 🇪🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos2.spareroom.co.uk/images/flatshare/listings/large/49/47/49477486.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Madison Williams</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            I had suspicions about my husband cheating, yet lacked concrete evidence. Upon hearing about a group of expert individuals, I sought their help to monitor his phone activity. Thanks to their assistance, I obtained the evidence I needed to confirm his infidelity. Grateful to this team for rescuing me from a false relationship.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[15%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[15%]'>Oregon, USA 🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/cb281daa21bc036868a52fe76634fa82.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Olivia Campbell</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
                I suspected an employee of siphoning money from my business. Despite receiving a salary that couldn't justify her sudden expensive purchases, coupled with noticeable losses in my business's capital, I was unsure how to proceed. A friend recommended Retrieve Digital Crypto. After an initial payment, their team conducted surveillance on her phone. Grateful to this team for safeguarding my financial interests.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>Scotland, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
