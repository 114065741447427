import React, { useEffect, useState } from 'react';
import { animated } from '@react-spring/web';
import { useSpring } from '@react-spring/web';

const Loader = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, 10000); // Set the duration in milliseconds (e.g., 10000 for 10 seconds)

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Run this effect only once when the component mounts

  const props = useSpring({
    from: { rotate: 0 },
    to: { rotate: 360 },
    loop: { reverse: true },
    config: { duration: 1000 },
  });

  return (
    showLoader && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
        <animated.div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50px',
            height: '50px',
            borderTop: '8px solid #38be57',
            borderBottom: '8px solid #38be57',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderRadius: '50%',
            transform: props.rotate.interpolate((r) => `rotate(${r}deg)`),
          }}
        >
          {/* <img src="ctxx.png" alt="Logo" /> */}
        </animated.div>
      </div>
    )
  );
};

export default Loader;
