import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTe0o0G43r3leX8RHfDdnb1-zI7P9m3pX9DOrS-mTHPTFTCKz5uHbF96U0_tdzUapmkdU&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Seraphina Hawthorne</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            As a contented patron of DraxallCorp, I turned to them for aid in unlocking my phone. Their proficiency and adeptness in unlocking services left a lasting impression. The team's approach and dedication to addressing my concern were exceptional, ensuring a swift and seamless solution. I appreciate their assistance and professionalism, elevating my interaction with them to an exceptional level.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-4'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-4'>Savannah GA, USA  🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/ExW1qI9U4AANcAR.jpg:large' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Thaddeus Fairchild</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
                I found myself in a predicament with an iCloud-locked iPhone that I couldn't operate or access. DraxallCorp intervened and adeptly unlocked my device. Their expertise in iCloud unlocking transformed the situation, granting me complete access to my device effortlessly. I deeply appreciate their swift and efficient service.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[16%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[16%]'>Olympia WA, USA  🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLsjnRcBb9NCfEoNn7kgdv8-W_ytI0PxACXTKcewFucJxro5NO3UTNIKnQ17IsJ1_T48&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Leander Beauchamp</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
                    An individual was targeting me with scams, and I felt a pressing need to trace their whereabouts. DraxallCorp employed their expertise to conduct surveillance on the scammer's phone, collecting vital information to pinpoint and apprehend the scammer. Their effective intelligence service was invaluable, ensuring the scammer's accountability for their deeds.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[10%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[10%]'>Bath, England 🏴󠁧󠁢󠁥󠁮󠁧󠁿   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.youngisthan.in/wp-content/uploads/2017/08/sel-2-600x600.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Evangeline Sinclair</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
                I encountered a situation where I was barred from my iCloud account and all my iOS devices. DraxallCorp promptly intervened, initiating a logout from all devices. Their smooth procedure assured my account's security, facilitating a trouble-free return to my iCloud account. Their proficiency in iCloud security was undeniably remarkable.
             </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[11%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[11%]'>alifax, Nova Scotia, Canada 🇨🇦  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://hips.hearstapps.com/cos.h-cdn.co/assets/16/06/1454955821-hotspot-disney-princess-sarah-009.jpg?resize=980:*' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Gwendolyn Arlington</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
             During a hectic business trip, my iCloud account unexpectedly locked, causing a halt in accessing essential documents. DraxallCorp promptly intervened, securing a logout from all devices and enabling hassle-free account re-entry. Their swift expertise eased a critical moment, ensuring vital access to my files during my trip.
             </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[16%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[16%]'>Edinburgh, Scotland, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
