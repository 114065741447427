import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { BsCheck2All } from "react-icons/bs";
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circular";
import Circularxx from "./hooks/Circularxx";
import RomanceReview from "./hooks/RomanceReview";
import { Link } from 'react-router-dom';
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const Bitcoin = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#0fc3c3'
    }
  return (
   <>
   <Progress/>

   <div className='overflow-hidden'>
   <section class="Romance">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                               <div className='flex mt-10'>
                                <p>Home<i class="ri-arrow-right-s-line pt-1 text-[#38be57]"></i></p>
                                <p>Services<i class="ri-arrow-right-s-line pt-1 text-[#38be57]"></i></p>
                               </div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[3rem] font-bold text-white'>Romance Scam<br /> Recovery</p>
                                </motion.div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className='bg-black h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-[#c8c8c8]'>
                                Within the intricate web of romance scams, where tales of love cloak vanished wealth, assets, and ill-gotten gains, the pursuit of justice assumes paramount significance. In this complex landscape, we, DraxallCorp, emerge as a beacon of optimism, unwavering in our commitment to recover wrongfully acquired possessions. Our expertise lies in disentangling funds ensnared by deceitful maneuvers. Whether seeking financial restitution, property recovery, or the reclaiming of misappropriated loans, we navigate these treacherous waters with finesse.
                                </p> <br />

                                <p className='text-[#c8c8c8]'>
                                Furthermore, our unique proficiency lies in reacquiring funds entangled within the intricate networks of fabricated romantic personas, ensuring truth prevails over deception. Entrust us for restitution. Empowered by steadfast determination, we deploy a diverse array of strategies and dedicated resources aimed at reclaiming what is rightfully yours. Together, we unravel the complex networks of deceit, restoring not only your financial stability but also your faith in humanity.
                                </p>
                            </div>
                        </div>
                        <div className='bg-green-60 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://media.istockphoto.com/id/1374841003/photo/young-woman-crying.jpg?s=612x612&w=0&k=20&c=mxFoSiMmwgzA2vCxuuEHXDn7e_PQlvzra8_byPgKH8g=" 
                                alt="" className='rounded-md'/>
                            </div>
                        </div>
                    </div>
                </div>





                <div className='bg-[#0b0c10] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl text-white'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on Romance <span className='top__b'>Scam</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Loanded Money Recovery </p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Military Romance Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Money Recovery From Romance Scammer</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Properties Recovery</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hook Up Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Broker Investmet Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Bank Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hook Up Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Bank Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#38be57]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-black"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        At DraxallCorp Specialists, we stand as your unwavering companion on the journey to recovery from romance scams. Our dedication extends beyond the restoration of your financial equilibrium, aiming to reignite your trust in humanity. Our meticulous strategies are uniquely crafted to liberate funds ensnared by deceptive snares, ensuring the ascendancy of truth.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#38be57]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Comprehensive Solutions:</span> From dismantling fabricated romance accounts to reclaiming lost finances, our holistic approach empowers you to confront romance scams with resolute confidence.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#38be57]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Verified Success:</span> Showcasing a proven history of successful recoveries, we demonstrate an impressive track record in efficiently reclaiming lost funds for our valued clients.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#38be57]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Emotional Resilience:</span> Acknowledging the emotional challenges inherent in recovering from a romance scam, our devoted team provides compassionate assistance throughout the process. We are committed to aiding in the restoration of your confidence and trust in the digital sphere.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#38be57]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Restoring Faith:</span>  Our primary mission is to revive your belief not only in financial security but also in the potential for meaningful human connections. We firmly uphold the belief that everyone deserves justice and the opportunity to rebuild their lives after falling victim to a scam.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
                </div>


                <div className='mx-auto overflow-hidden  md:max-w-7xl text-[white]'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Review on Romance  <span className='top__b'>Scam</span> </span></p>
                    </div>
                    <RomanceReview/>
                </div>
                </div>

               
                
            </div>


            <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>

   </div>
   
   
   <Footer/>
   
   
   </>
  )
}

export default Bitcoin